import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "d-md-inline text-muted" }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { class: "d-md-inline text-muted" }
const _hoisted_6 = { class: "mb-1" }
const _hoisted_7 = { class: "" }
const _hoisted_8 = { class: "d-none d-md-inline text-muted" }
const _hoisted_9 = { class: "w-200px text-end fs-7 pe-9" }
const _hoisted_10 = { class: "fw-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_RelativeTime = _resolveComponent("RelativeTime")!

  return (_openBlock(), _createElementBlock("tr", null, [
    _createElementVNode("td", _hoisted_1, [
      _createVNode(_component_router_link, {
        to: `/mail/` + _ctx.mail.id,
        class: "text-dark"
      }, {
        default: _withCtx(() => [
          (_ctx.mail.folder ==='SENT')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", _hoisted_2, "To: " + _toDisplayString(_ctx.mail.recipient[0].name), 1),
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.mail.recipient[0].email), 1)
              ], 64))
            : _createCommentVNode("", true),
          (_ctx.mail.folder ==='INBOX')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.mail.sender.name), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.mail.sender.email), 1)
              ], 64))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_router_link, {
          to: `/mail/`  + _ctx.mail.id,
          class: "text-dark"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.mail.subject), 1),
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.mail.bodyPreview), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("td", _hoisted_9, [
      _createElementVNode("span", _hoisted_10, [
        _createVNode(_component_RelativeTime, {
          date: _ctx.mail.received
        }, null, 8, ["date"])
      ])
    ])
  ]))
}
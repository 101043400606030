
import {defineComponent, ref, watch} from 'vue'
import MailService from "@/core/services/MailService";
import Pagination from "@/components/base/paging/Pagination.vue";
import MailBoxItem from "@/views/mail/MailBoxItem.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "MailPage",
  components: {MailBoxItem, Pagination},
  props: {
    query: {type: Object, required: true},
    page: {type: Number, default: 1},
  },
  setup(props) {
    const viewHeight = ref(700);
    const router = useRouter();
    const data = ref<any>({total: 0, data: []});
    const filter = ref<any>({
      paginationPage: props.page,
      paginationSize: 20,
      key: {s: 'sort:received:desc'},
    })


    filter.value.key = Object.assign(filter.value.key, props.query);
    const loading = ref(true);
    const loadData = () => {
      loading.value = true
      MailService.findAll(filter.value).then(res => {
        data.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    const handleUpdatePage = (event) => {
      filter.value.paginationPage = event;
      const buildQuery = ref<any>({})
      if (props.query.folder) {
        buildQuery.value.f = props.query.folder
      }
      if (props.query.archive === 'true') {
        buildQuery.value.a = 'true'
      }
      buildQuery.value.page = filter.value.paginationPage
      if (props.query.tag) {
        router.push({name: 'emailTags', params: {id: props.query.tag}, query: buildQuery.value});
      }
      if (props.query.mailbox) {
        router.push({name: 'emailsBox', params: {id: props.query.mailbox}, query: buildQuery.value});
      }
    };

    loadData()
    watch(() => props.query, cb => {
      filter.value.key = Object.assign(filter.value.key, cb);
      filter.value.paginationPage = props.page;
      loadData();
    })
    return {
      filter,
      viewHeight,
      data,
      loading,
      handleUpdatePage,
    }
  },
  methods: {
    onMarked(data) {
      const mark = this.data.data.find(item => item.id === data.id)
      mark.marked = data.marked
    }
  }
})

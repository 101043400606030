
import {defineComponent} from 'vue'
import MailService from "@/core/services/MailService";
import RelativeTime from "@/components/base/datetime/RelativeTime.vue";

export default defineComponent({
  name: "MailBoxItem",
  components: {RelativeTime},
  props: {
    box: {},
    mail: {type: Object, required: true},
  },
  emits: ['marked'],
  methods: {
    onMarked(id) {
      MailService.mark(id).then(res => {
        this.$emit('marked', res);
      })
    }
  }
})
